import { Link } from "gatsby"
import React, { useState, useCallback } from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "./GlobalStyles"
import MenuIcon from "../images/hamburger-menu.svg"
import { withBreakpoints } from "react-breakpoints"
import MobileMenu from "./MobileMenu"

const isActiveHelper = path => {
  if (typeof window === "undefined") return

  return window.location.pathname.startsWith(path).toString()
}

const Header = ({ breakpoints, currentBreakpoint }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const toggleMobileMenu = useCallback(
    () => setMobileMenuOpen(isOpen => !isOpen),
    [mobileMenuOpen]
  )

  return (
    <>
      <Nav>
        <H1>
          <MainNavItem to="/">Steven Musumeche</MainNavItem>
        </H1>

        {breakpoints[currentBreakpoint] <= breakpoints.mobile ? (
          <RightNavWrapperMobile>
            <MobileMenuButton type="button" onClick={toggleMobileMenu}>
              <img src={MenuIcon} alt="menu" />
            </MobileMenuButton>
          </RightNavWrapperMobile>
        ) : (
          <RightNavWrapperDesktop>
            <MenuItem to="/blog" isactive={isActiveHelper("/blog")}>
              Blog
            </MenuItem>
            <MenuItem
              to="https://docs.google.com/document/d/e/2PACX-1vS0pQuAFimtB9BRfcNxJviP0GcqqmfZTPkRyCD8iFia4pRKWYoomCd49NiOVG_RCX2f9g2AM6F1PITy/pub"
              target="_blank"
            >
              Resume
            </MenuItem>
            <MenuItem to="/music" isactive={isActiveHelper("/music")}>
              Music
            </MenuItem>
            <MenuItem to="/fishing" isactive={isActiveHelper("/fishing")}>
              Fishing
            </MenuItem>
          </RightNavWrapperDesktop>
        )}
      </Nav>
      <MobileMenu isOpen={mobileMenuOpen}>mobile menu!</MobileMenu>
    </>
  )
}

export default withBreakpoints(Header)

const H1 = styled.h1`
  margin: 0;
  line-height: inherit;
`

const RightNavWrapperDesktop = styled.div`
  display: flex;
  font-size: 0.75em;
  height: 55px;
`

const RightNavWrapperMobile = styled.div`
  height: 30px;
`

const Nav = styled.nav`
  font-family: Montserrat, helvetica neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  padding: 0;
  text-transform: uppercase;
  font-feature-settings: kern;
  letter-spacing: 0.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.black};
`

const MenuItem = styled(Link)`
  box-sizing: border-box;
  font-family: Montserrat, helvetica neue, Helvetica, Arial, sans-serif;
  display: inline-flex;
  flex: 1;
  align-items: center;
  padding: 1em 2em;
  color: ${colors.white};
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    background-color: ${colors.darkGrey};
    color: ${colors.white};
    box-shadow: inset 0px 5px 0px 0px ${colors.darkGrey};
  }
  box-shadow: inset 0px 5px 0px 0px
    ${props => (props.isactive === "true" ? colors.darkGrey : colors.black)};

  ${mediaQueries.small} {
    padding: 0.5em 1em;
    &:hover {
      background-color: ${colors.black};
      box-shadow: none;
    }
  }
`

const MainNavItem = styled(MenuItem)`
  font-size: 16px;
  height: 55px;
  ${mediaQueries.small} {
    font-size: 14px;
  }
`

const MobileMenuButton = styled.button`
  border: 0;
  background-color: transparent;
  margin: 0 1em 0 0;
  padding: 0;
`
