import { createGlobalStyle } from "styled-components"

// https://coolors.co/423e37-e3b23c-edebd7-a39594-6e675f
export const colors = {
  black: "#423E37",
  white: "#F8F8F8",
  yellow: "#E3B23C",
  mediumGrey: "#A39594",
  darkGrey: "#6E675F",
}

export const mediaQueries = {
  small:
    "@media only screen and (min-device-width: 325px) and (max-device-width: 768px)",
  large: "@media only screen and (min-device-width: 768px)",
}

export default createGlobalStyle`
html {
  font-size: 16px;
  margin:0;
  padding:0;
  height:100%;
}

body {
  color: ${colors.black}
  background-color: ${colors.white}
  font-family: "merriweather", serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  letter-spacing: 0.01rem;
  margin:0;
  padding:0;
  height:100%;
}

#___gatsby {
  position: relative;
  min-height: 100%;

  & > div {
    min-height: 100%;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1,h2,h3,h4,h5,h6 {
  font-family: open sans,sans-serif;
  line-height: 1.5;
}

h1 {
  line-height: 1.1;
}

p {
  font-size: 1rem;
  line-height: 1.75rem;
}

a {
  color: ${colors.darkGrey};
  &:hover {
    color: ${colors.mediumGrey};
  }
}

li {
  padding: .15em;
  line-height: 1.25;
}

.resume-skills {
  display:flex; 
  justify-content: flex-start; 
  flex-wrap: wrap;
  padding: 1em 0;

  & ul {
    margin: 0;
    min-width: 220px;
  }
}
`
