import React from "react"
import { colors } from "./GlobalStyles"

const Footer = () => (
  <footer
    css={`
      background-color: ${colors.white};
      border-top: 1px solid ${colors.mediumGrey};
      color: ${colors.darkGrey};
      padding: 1em;
      font-size: 0.7em;
      height: 36px;
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
    `}
  >
    © {new Date().getFullYear()} Steven Musumeche. Contact me{" "}
    <a href="mailto:steven@musumeche.com">via email</a>.
  </footer>
)

export default Footer
