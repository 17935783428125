import React from "react"
import Header from "./header"
import Footer from "./Footer"
import styled from "styled-components"
import ReactBreakpoints from "react-breakpoints"

import NormalizeCss from "./NormalizeCss"
import GlobalStyles from "./GlobalStyles"

const breakpoints = {
  mobile: 320,
  desktop: 768,
}

const Layout = ({ children }) => {
  return (
    <ReactBreakpoints
      breakpoints={breakpoints}
      debounceResize={true}
      debounceDelay={100}
    >
      <NormalizeCss />
      <GlobalStyles />
      <Header />
      <ChildWrapper>{children}</ChildWrapper>
      <Footer />
    </ReactBreakpoints>
  )
}

export default Layout

const ChildWrapper = styled.div`
  padding-bottom: 36px;
`
