import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { colors } from "./GlobalStyles"

const MobileMenu = ({ isOpen }) => {
  return (
    <Wrapper isOpen={isOpen}>
      <MenuItem to="/blog">Blog</MenuItem>
      <MenuItem
        to="https://docs.google.com/document/d/e/2PACX-1vS0pQuAFimtB9BRfcNxJviP0GcqqmfZTPkRyCD8iFia4pRKWYoomCd49NiOVG_RCX2f9g2AM6F1PITy/pub"
        target="_blank"
      >
        Resume
      </MenuItem>
      <MenuItem to="/music">Music</MenuItem>
      <MenuItem to="/fishing">Fishing</MenuItem>
    </Wrapper>
  )
}

export default MobileMenu

const Wrapper = styled.div`
  transition: height 0.2s ease-in;
  height: ${props => (props.isOpen === true ? "180px" : "0px")};
  background-color: ${colors.black};
  overflow: hidden;
`

const MenuItem = styled(Link)`
  box-sizing: border-box;
  display: block;
  font-family: Montserrat, helvetica neue, Helvetica, Arial, sans-serif;
  color: ${colors.white};
  text-decoration: none;
  color: ${colors.white};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-feature-settings: kern;
  letter-spacing: 0.2em;
  padding: 1em;
  border-top: 1px solid ${colors.darkGrey};
`
